var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Basic Progress"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "demo-vertical-spacing"
  }, [_c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Reticulating splines… " + _vm._s(_vm.value + '%') + " ")]), _c('b-progress', {
    attrs: {
      "max": "100"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Reticulating splines… " + _vm._s(_vm.value1 + '%') + " ")]), _c('b-progress', {
    attrs: {
      "max": "100"
    },
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  })], 1), _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Reticulating splines… " + _vm._s(_vm.value2 + '%') + " ")]), _c('b-progress', {
    attrs: {
      "max": "100"
    },
    model: {
      value: _vm.value2,
      callback: function callback($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  })], 1), _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Reticulating splines… " + _vm._s(_vm.value3 + '%') + " ")]), _c('b-progress', {
    attrs: {
      "max": "100"
    },
    model: {
      value: _vm.value3,
      callback: function callback($$v) {
        _vm.value3 = $$v;
      },
      expression: "value3"
    }
  })], 1), _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Reticulating splines… " + _vm._s(_vm.value4 + '%') + " ")]), _c('b-progress', {
    attrs: {
      "max": "100"
    },
    model: {
      value: _vm.value4,
      callback: function callback($$v) {
        _vm.value4 = $$v;
      },
      expression: "value4"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }