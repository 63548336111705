var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Striped Progress"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeStripped) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v("Set striped to apply a stripe via CSS gradient over the progress bar's background variant.")]), _c('div', {
    staticClass: "demo-vertical-spacing"
  }, [_c('b-progress', {
    attrs: {
      "value": "25",
      "max": "100",
      "striped": ""
    }
  }), _c('b-progress', {
    staticClass: "progress-bar-secondary",
    attrs: {
      "value": "35",
      "max": "100",
      "striped": "",
      "variant": "secondary"
    }
  }), _c('b-progress', {
    staticClass: "progress-bar-success",
    attrs: {
      "value": "45",
      "max": "100",
      "striped": "",
      "variant": "success"
    }
  }), _c('b-progress', {
    staticClass: "progress-bar-danger",
    attrs: {
      "value": "55",
      "max": "100",
      "striped": "",
      "variant": "danger"
    }
  }), _c('b-progress', {
    staticClass: "progress-bar-warning",
    attrs: {
      "value": "65",
      "max": "100",
      "striped": "",
      "variant": "warning"
    }
  }), _c('b-progress', {
    staticClass: "progress-bar-info",
    attrs: {
      "value": "75",
      "max": "100",
      "striped": "",
      "variant": "info"
    }
  }), _c('b-progress', {
    staticClass: "progress-bar-dark",
    attrs: {
      "value": "85",
      "max": "100",
      "striped": "",
      "variant": "dark"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }